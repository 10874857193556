var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.config.size_value},[(!_vm.config.form.active || _vm.config.fconfig.readonly)?_c('span',{domProps:{"innerHTML":_vm._s(
      _vm.config.form.formData[_vm.config.fconfig.translate][_vm.config.form.cur_lang][
        _vm.config.fname
      ]
    )}}):_vm._e(),(_vm.config.form.active && !_vm.config.fconfig.readonly)?_c('div',[_c('CButtonGroup',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip.hover",value:({
        content: "Seleziona la lingua per cui vuoi modificare i dati",
        color: "secondary",
      }),expression:"{\n        content: `Seleziona la lingua per cui vuoi modificare i dati`,\n        color: `secondary`,\n      }",modifiers:{"hover":true}}]},_vm._l((_vm.languages),function(lang){return _c('CButton',{key:lang.language,attrs:{"variant":"outline","color":"secondary","size":"sm","pressed":lang.language == _vm.clang ? true : false},on:{"click":function($event){_vm.clang = lang.language}}},[_c('CIcon',{attrs:{"content":lang.flag}}),_vm._v(" "+_vm._s(lang.title)+" ")],1)}),1),_c('ckeditor',{attrs:{"editor":_vm.CkEditor},model:{value:(
        _vm.config.form.formData[_vm.config.fconfig.translate][_vm.clang][_vm.config.fname]
      ),callback:function ($$v) {_vm.$set(_vm.config.form.formData[_vm.config.fconfig.translate][_vm.clang], _vm.config.fname, $$v)},expression:"\n        config.form.formData[config.fconfig.translate][clang][config.fname]\n      "}}),_c('br'),_c('small',{staticClass:"form-text text-muted w-100"},[_vm._v(_vm._s(_vm.config.fconfig.description))])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }