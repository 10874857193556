<template>
  <!-- <dt
      :class="size_value"
      v-else-if="field.translate && field.type == 'CKInput'"
    >
      <span
        v-if="!form.active || field.readonly"
        v-html="form.formData[field.translate][$i18n.locale][field.name]"
      />
      <div v-if="form.active && !field.readonly">
        <ckeditor
          :editor="CkEditor"
          v-model="
            form.formData[field.translate][trans_current_lang][field.name]
          "
        ></ckeditor>
      </div>
    </dt> -->
  <div :class="config.size_value">
    <span
      v-if="!config.form.active || config.fconfig.readonly"
      v-html="
        config.form.formData[config.fconfig.translate][config.form.cur_lang][
          config.fname
        ]
      "
    />
    <div v-if="config.form.active && !config.fconfig.readonly">
      <CButtonGroup
        v-c-tooltip.hover="{
          content: `Seleziona la lingua per cui vuoi modificare i dati`,
          color: `secondary`,
        }"
      >
        <CButton
          v-for="lang in languages"
          :key="lang.language"
          variant="outline"
          color="secondary"
          size="sm"
          :pressed="lang.language == clang ? true : false"
          v-on:click="clang = lang.language"
        >
          <CIcon :content="lang.flag" /> {{ lang.title }}
        </CButton>
      </CButtonGroup>
      <ckeditor
        :editor="CkEditor"
        v-model="
          config.form.formData[config.fconfig.translate][clang][config.fname]
        "
      ></ckeditor
      ><br />
      <small class="form-text text-muted w-100">{{
        config.fconfig.description
      }}</small>
    </div>
  </div>
</template>

<script>
import { languages } from "../../config/global";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
  name: "FieldTransText",
  props: ["config"],
  components: { ckeditor: CKEditor.component },
  data() {
    return {
      languages: languages,
      clang: this.config.form.cur_lang,
      CkEditor: ClassicEditor,
    };
  },
};
</script>
